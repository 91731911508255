@font-face {
  font-family: "SF Pro Display";
  src: url(../assets/fonts/FontsFree-Net-SFProDisplay-Regular.ttf);
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(../assets/fonts/FontsFree-Net-SFProDisplay-Semibold.ttf);
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(../assets/fonts/FontsFree-Net-SFProDisplay-Heavy.ttf);
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
